import React from "react";
import getStaticPropsWrapper from "@/lib/utils/getStaticPropsWrapper";
import Home from "@/components/pages/home/Home";
import {getRecentStoriesByCategory} from "@/lib/api/sanity/api";
import {flatten, shuffle} from "lodash";

export default Home;

export const getStaticProps = getStaticPropsWrapper(async ({wrapperProps}) => {
  const {props} = wrapperProps;
  const featuredStories = await getFeaturedStoryLists(props);
  return {
    props: {
      featuredStories,
    }
  }
})

async function getFeaturedStoryLists({site,config}) {
  const {featuredStoryLists = []} = site;
  const result = [];
  while(featuredStoryLists.length) {
    const list = featuredStoryLists.shift();
    const minimum = result.length ? 3 : 2; //2 for the first one, 3 after that
    const {stories = [], categories = []} = list;
    if (stories.length >= minimum) {
      result.push(list);
      continue;
    }
      // Get recent stories for all the fallback categories
    const moreStories = await Promise.all(categories.map(category => getRecentStoriesByCategory({
      categoryId: category._id,
      config,
      excludeIds: [
        site.hero?.id,
        site.printHero?.id,
        ...stories.map(x => x.id),
      ],
      limit: minimum - stories.length,
    })));
    // Shuffle the returned stories, and top up myStories with as few as possible in order to reach the minimum number of stories
    result.push({
      ...list,
      stories: [...stories, ...shuffle(flatten(moreStories)).slice(0, minimum - stories.length)]
    });
  }
  return result;
}
