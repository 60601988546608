import React from "react";
import {JsonLd} from "react-schemaorg";
import {SITE_URL} from "@/lib/utils/utils";

export default function OrganisationSchema({site}) {
  return (
      <JsonLd
          item={{
            "@context": "https://schema.org",
            "@type": "NewsMediaOrganization",
            name: `${site.name}`,
            url: `${SITE_URL}`,
            logo: {
              "@type": "ImageObject",
              url: `${SITE_URL}/images/${site.slug}/schema-logo.png`,
            },
            contactPoint: [
              {
                "@type": "ContactPoint",
                telephone: "0800776247",
                contactType: "Customer Service",
              }
            ],
          }}
      />
  );
}
