import React, {useEffect, useRef, useState} from "react";
import styles from './Styles.module.scss';
import Layout from "@/components/layout/Layout";
import {useContext} from "@/lib/utils/context";
import classNames from 'classnames/bind';
import Ad from "@/components/ad/Ad";
import OrganisationSchema from "@/components/schema/OrganizationSchema";
import SignupWidget from "@/components/widgets/signup/SignupWidget";
import TrendingStories from "@/components/widgets/trendingStories/TrendingStories";
import NewsletterSignupWidget from "@/components/widgets/newsletterSignup/NewsletterSignupWidget";
import StoryList from "@/components/widgets/storyList/StoryList";
import Button from "@/components/button/Button";
import {isDigitalSubscribed} from "@/lib/utils/authUtils";
import {shuffle} from "lodash";
import {getRecentStories} from "@/lib/api/sanity/api";
import StoryCard from "@/components/card/story/StoryCard";
import XPlatformAd from "@/components/ad/xplatform/XPlatformAd";
import useRandom from "@/lib/utils/useRandom";

const cx = classNames.bind(styles);

const DATA_PAGE_SIZE = 12;
const PRE_FETCH_BUFFER = 12;

export default function Home({featuredStories}) {
  const featuredStoryLists = [...featuredStories];

  const {site, campaigns, auth, user, config} = useContext();
  const {hero, printHero} = site;
  const {latestPageSize} = config;
  const [homepageTakeover, setHomepageTakeover] = useState(null);

  const scrollRef = useRef({});
  const left = useRef(null);
  const right = useRef(null);
  const columns = [
    {elementRef: left, offsetRef: useRef()},
    {elementRef: right, offsetRef: useRef()},
  ]

  const rand = useRandom();

  function getRandom(i) {
    return Math.floor(rand() * i);
  }

  function getRandomNode(nodes) {
    if (!nodes?.length) {
      return null;
    }
    const randomIndex = getRandom(nodes.length);
    return nodes[randomIndex];
  }

  const getHomepageTakeoverCampaign = () => {
    const homepageTakeoverCampaigns = campaigns.filter(c => c.homepageTakeover && c.homepageTakeover.length);
    return homepageTakeoverCampaigns.length && getRandomNode(homepageTakeoverCampaigns);
  }

  function getHomepageTakeover() {
    const campaign = getHomepageTakeoverCampaign();
    return campaign && getRandomNode(campaign.homepageTakeover);
  }

  function selectAd() {
    const homepageTakeoverCampaign = getHomepageTakeoverCampaign();
    if (homepageTakeoverCampaign) {
      return {
        type: 'ad',
        mobile: getRandomNode(homepageTakeoverCampaign.mobile),
        tablet: getRandomNode(homepageTakeoverCampaign.tablet),
        desktop: getRandomNode(homepageTakeoverCampaign.desktop),
      }
    }
    return {
      type: 'ad',
      mobile: getRandomNode(getRandomNode(campaigns.filter(x => x.mobile?.length))?.mobile),
      tablet: getRandomNode(getRandomNode(campaigns.filter(x => x.tablet?.length))?.tablet),
      desktop: getRandomNode(getRandomNode(campaigns.filter(x => x.desktop?.length))?.desktop),
    };
  }

  useEffect(() => {
    if (campaigns.length) {
      setHomepageTakeover(getHomepageTakeover())
    }
  }, [campaigns]);

  function BannerAd() {
    const ad = homepageTakeover
        ? homepageTakeover.center
        : getRandomNode(getRandomNode(campaigns.filter(x => x.banner?.length))?.banner);
    if (!ad) {
      return null;
    }
    return <div className={styles.bannerAd}>
      <Ad ad={ad}/>
      <div>Advertisement</div>
    </div>
  }

  const showSignupWidget = auth
      && !isDigitalSubscribed(user);

  const [trendingStories, setTrendingStories] = useState([]);
  const [recentStories, setRecentStories] = useState([]);
  const [latestIndex, setLatestIndex] = useState(latestPageSize);
  const mainFeaturedStoryList = featuredStoryLists.shift();
  const excludeIds = [
    hero?.id,
    printHero?.id,
    ...trendingStories.map(x => x.id),
    ...mainFeaturedStoryList?.stories.map(x => x.id) ?? [],
    ...featuredStoryLists.flatMap(x => x.stories.map(y => y.id)) ?? [],
  ];
  const filteredRecentStories = recentStories.filter(x => !excludeIds.includes(x.id));
  const [loadingMoreStories, setLoadingMoreStories] = useState(false);
  const [eof, setEof] = useState(false);

  useEffect(() => {
    if (!loadingMoreStories && !eof && filteredRecentStories.length < latestIndex + PRE_FETCH_BUFFER) {
      getMoreRecentStories().catch(console.log);
    }
  }, [recentStories, latestIndex, eof, loadingMoreStories])

  useEffect(() => {
    if (trendingStories.length < 3) {
      setTrendingStories(shuffle(filteredRecentStories.filter(x => x.id !== hero?.id && x.id != printHero?.id)).slice(0, 3))
    }
  }, [recentStories])

  async function getMoreRecentStories() {
    try {
      setLoadingMoreStories(true);
      const moreStories = await getRecentStories({
        config,
        page: recentStories.length / DATA_PAGE_SIZE,
        limit: DATA_PAGE_SIZE,
      });
      if (moreStories.length < DATA_PAGE_SIZE) {
        setEof(true);
      }
      setRecentStories((prev) => [...prev, ...moreStories]);
    } finally {
      setLoadingMoreStories(false);
    }
  }

  function onLoadMoreStories() {
    setLatestIndex(prev => prev + latestPageSize);
  }

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  function onScroll(e) {
    const navBarHeight = 129;
    const prevScrollOffset = scrollRef.current.scrollOffset || 0;
    const scrollOffset = e.target.documentElement.scrollTop;
    const scrollDiff = scrollOffset - prevScrollOffset;
    scrollRef.current.scrollOffset = scrollOffset;
    columns.forEach(({elementRef, offsetRef}) => {
      const prevWidgetsOffset = offsetRef.current || 0;
      const minWidgetsOffset = Math.min(navBarHeight, window.innerHeight - elementRef.current?.clientHeight ?? 0);
      const maxWidgetsOffset = Math.min(navBarHeight, prevWidgetsOffset - scrollDiff);
      const offset = Math.max(minWidgetsOffset, maxWidgetsOffset);
      offsetRef.current = offset;
      elementRef.current?.setAttribute("style", `top: ${offset}px;`);
    });
  }

  const videoUrl = site.video?.file?.asset?.url;

  return (
      <Layout nav>
        <div className={cx(styles.container, {homepageTakeover})}>
          <OrganisationSchema site={site}/>
          {homepageTakeover?.left && <Ad ad={homepageTakeover.left}
                                         className={cx(styles.homepageTakeover, styles.left)}/>}
          {homepageTakeover?.right && <Ad ad={homepageTakeover.right}
                                          className={cx(styles.homepageTakeover, styles.right)}/>}
          <BannerAd/>
          <div className={styles.mainGrid}>
            <div>
              <div ref={left} className={cx(styles.column)}>
                {hero && <StoryCard home hero story={hero}/>}
                <XPlatformAd className={styles.mobileAd}
                             ad={selectAd()}
                             homepageTakeover={homepageTakeover}/>
                <div className={cx(styles.trendingStoriesContainer)}>
                  <div className={styles.sectionHeading}>Trending</div>
                  <TrendingStories stories={trendingStories}/>
                </div>
                {showSignupWidget && (
                    <div className={styles.signUpWidget}>
                      <SignupWidget/>
                    </div>
                )}
                {mainFeaturedStoryList && (<div className={styles.featuredArticlesContainer}>
                  <div className={styles.sectionHeading}>{mainFeaturedStoryList.title}</div>
                  <StoryList className={styles.featuredArticlesInnerContainer}
                             stories={mainFeaturedStoryList.stories}/>
                </div>)}
              </div>
            </div>
            <div className={styles.desktopSideWidgets}>
              <div ref={right} className={cx(styles.column, styles.widgets)}>
                <div className={cx(styles.trendingStoriesContainer)}>
                  <div className={styles.sectionHeading}>Trending</div>
                  <TrendingStories stories={trendingStories}/>
                </div>
                {videoUrl && (
                    <video className={styles.videoPlayer} muted autoPlay loop playsInline>
                      <source src={videoUrl} type="video/mp4"/>
                    </video>
                )}
                {/*{!user && <NewsletterSignupWidget/>}*/}
              </div>
            </div>
            <div className={styles.bottomWidgets}>
              {printHero && <StoryCard className={styles.printHeroCard} printHero story={printHero}/>}
              <XPlatformAd className={styles.mobileAd}
                           ad={selectAd()}
                           homepageTakeover={homepageTakeover}/>
              <BannerAd/>
              {featuredStoryLists.map((list, i) => (
                  <div key={i} className={styles.moreFeaturedArticlesContainer}>
                    <div className={styles.sectionHeading}>{list.title}</div>
                    <StoryList className={styles.featuredArticlesInnerContainer}
                               stories={list.stories}/>
                  </div>
              ))}
              {featuredStoryLists[1] && (null)}
              <div className={styles.latestArticlesContainer}>
                <div className={styles.sectionHeading}>Latest</div>
                <StoryList className={styles.latestArticlesInnerContainer}
                           stories={filteredRecentStories.slice(0, latestIndex)}/>
              </div>
              {!eof && (
                  <div className={styles.loadMoreStoriesButtonContainer}>
                    <Button
                        onClick={onLoadMoreStories}
                        submitting={loadingMoreStories && filteredRecentStories.length < latestIndex}>
                      More Stories
                    </Button>
                  </div>
              )}
            </div>
          </div>
        </div>
      </Layout>
  )
}
